import styled from "styled-components";

export const MusicContainer = styled.div`
	height: 100%;
	overflow-y: scroll;
	/* padding-right: 0.5rem; */
	/* background: red; */
`;
export const MusicList = styled.div`
	/* background: red; */
	display: flex;
	flex-wrap: wrap;
`;
