import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyAgArpnCsNXJN_4fia8gdeQ5UH0tUgoqH4",
	authDomain: "eatdrinksing.firebaseapp.com",
	projectId: "eatdrinksing",
	storageBucket: "eatdrinksing.appspot.com",
	messagingSenderId: "886076681470",
	appId: "1:886076681470:web:3d15ed961d7cffecfcc9af",
	measurementId: "G-5GC4TTHV50"
};
firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();
const auth = firebase.auth();


export {
	firestore,
	auth,
};
