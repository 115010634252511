export const SAVE_ADMIN_USER = "SAVE_ADMIN_USER";
export const REMOVE_ADMIN_USER = "REMOVE_ADMIN_USER";

export const saveAdminUserAction = (data) => {
	return {
		type: SAVE_ADMIN_USER,
		payload: data,
	};
};
export const removeAdminUserAction = (data) => {
	return {
		type: REMOVE_ADMIN_USER,
		payload: data,
	};
};
