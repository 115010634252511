// import React from "react";
// import ReactDOM from "react-dom";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";

// import { Provider } from "react-redux";
// import { ConnectedRouter } from "connected-react-router";
// import { PersistGate } from "redux-persist/integration/react";
// import { store, persistor } from "./Redux/store";
// import { history } from "./Redux/store";

// ReactDOM.render(
// 	<React.StrictMode>
// 		<Provider store={store}>
// 			<PersistGate loading={null} persistor={persistor}>
// 				{/* <ConnectedRouter history={history}> */}
// 					<App />
// 				{/* </ConnectedRouter> */}
// 			</PersistGate>
// 		</Provider>
// 	</React.StrictMode>,
// 	document.getElementById("root")
// );

// reportWebVitals();

// import React from "react";
// import ReactDOM from "react-dom";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";

// import { ToastProvider } from "react-toast-notifications";
// import { HashRouter as Router } from "react-router-dom";
// import { PersistGate } from "redux-persist/integration/react";
// import { Provider } from "react-redux";
// import { store, persistor } from "./redux/store";
// import { ConnectedRouter } from "connected-react-router";

// import { createBrowserHistory } from "react-router-dom";
// // const history = createBrowserHistory();

// ReactDOM.render(
// 	<React.StrictMode>
// 		<Provider store={store}>
// 			<PersistGate loading={null} persistor={persistor}>
// 				<Router>
// 					{/* <ConnectedRouter history={history}> */}
// 					<ToastProvider
// 						autoDismiss
// 						autoDismissTimeout={5000}
// 						placement="top-center"
// 					>
// 						<App />
// 					</ToastProvider>
// 					{/* </ConnectedRouter> */}
// 				</Router>
// 			</PersistGate>
// 		</Provider>
// 	</React.StrictMode>,
// 	document.getElementById("root")
// );

// reportWebVitals();

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./Redux/store";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./Redux/store";

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ConnectedRouter history={history}>
					<App />
				</ConnectedRouter>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

reportWebVitals();
