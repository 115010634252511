import { useState } from "react";

import {
	ItemContainer,
	Footer,
	TopContent,
	BottomContent,
	Container,
	Detail,
} from "./EventContainerStyle";

function EventContainer({ item }) {
	const [isExpand, setIsExpand] = useState(false);

	const expand = () => {
		setIsExpand(!isExpand);
	};

	return (
		<ItemContainer
			onClick={expand}
			style={{ height: isExpand ? "320px" : "80px" }}
		>
			{/* <Footer>More</Footer> */}
			<TopContent>
				<Container>
					<h6>Date</h6>
					<Detail>{item?.date}</Detail>
				</Container>
				<Container>
					<h6>Ocassion</h6>
					<Detail>{item?.ocasion}</Detail>
				</Container>
				<Container>
					<h6>Address</h6>
					<Detail>{item?.venu?.address}</Detail>
				</Container>
				<Container>
					<h6>Address 2</h6>
					<Detail>{item?.venu?.["address-2"]}</Detail>
				</Container>
			</TopContent>

			<BottomContent style={{ visibility: isExpand ? "visible" : "hidden" }}>
				<div
					style={{
						display: "flex",
						width: "100%",
						justifyContent: "space-between",
						marginBottom: "0.8rem",
					}}
				>
					<Container>
						<h6>City</h6>
						<Detail>{item?.venu?.city}</Detail>
					</Container>
					<Container>
						<h6>Guest Adult</h6>
						{item?.venu?.["guest(adults)"]}
					</Container>
					<Container>
						<h6>Guest Childred</h6>
						{item?.venu?.["guest(children)"]}
					</Container>
				</div>

				<div
					style={{
						display: "flex",
						width: "100%",
						justifyContent: "space-between",
					}}
				>
					<Container>
						<h6>Foods</h6>
						{item?.foods?.map((item, i) => (
							<Detail>{item.name}</Detail>
						))}
					</Container>
					<Container>
						<h6>Drinks</h6>
						{item?.drinks?.map((item, i) => (
							<Detail>{item.name}</Detail>
						))}
					</Container>
				</div>
			</BottomContent>
		</ItemContainer>
	);
}

export default EventContainer;
