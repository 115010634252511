export const SAVE_USER = "SAVE_USER";
export const REMOVE_USER = "REMOVE_USER";
export const SAVE_EVENT_DETAIL = "SAVE_EVENT_DETAIL";
export const SAVE_EVENT_ID = "SAVE_EVENT_ID";
export const FOOD_STEP = "FOOD_STEP";

export const saveUserAction = (data) => {
	return {
		type: SAVE_USER,
		payload: data,
	};
};
export const removeUserAction = () => {
	return {
		type: REMOVE_USER,
	};
};
export const saveEventDetailAction = (data) => {
	return {
		type: SAVE_EVENT_DETAIL,
		payload: data,
	};
};
export const saveEventIdAction = (data) => {
	return {
		type: SAVE_EVENT_ID,
		payload: data,
	};
};
export const saveFoodStepAction = (data) => {
	return {
		type: FOOD_STEP,
		payload: data,
	};
};
