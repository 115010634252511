import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Icons
import { IconButton } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

// Actions
import {
	saveAdminUserAction,
	removeAdminUserAction,
} from "../../Redux/actions";

// Components
import {
	BookingContainer,
	Header,
	ItemList,
	Form,
	Button,
} from "./BookingStyle";
import Modals from "../../Components/Modal/Modals";
import InputWrapper from "../../Components/InputWrapper/InputWrapper";
import ButtonNLoading from "../../Components/ButtonNLoading/ButtonNLoading";

// Functions
import { auth } from "../../Config/firebaseConfig";
import EventContainer from "./EventContainer/EventContainer";

function Booking() {
	// Initilization
	const history = useHistory();
	const dispatch = useDispatch();

	// Redux State
	const adminUser = useSelector((state) => state.adminUser.adminUser);

	// Hooks
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [allEventList, setAllEventList] = useState(null);

	// error
	const [emailError, setEmailError] = useState("");
	const [passwordError, setPasswordError] = useState("");

	//focus
	const emailFocus = () => {
		setEmailError("");
	};
	const passwordFocus = () => {
		setPasswordError("");
	};

	// UI
	const [isLoading, setIsLoading] = useState(false);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const close = () => {
		setIsOpenModal(!isOpenModal);
	};

	useEffect(() => {
		axios
			.get("https://us-central1-eatdrinksing.cloudfunctions.net/events")
			.then((res) => {
				console.log("Response get all event", res);
				setAllEventList(res.data.data.events);
			})
			.catch((err) => {
				console.error("Error get all event", err);
			});
	}, []);

	// Functions
	const login = (e) => {
		e.preventDefault();

		let valid = true;

		if (email === "") {
			setEmailError("Field is required");
			valid = false;
		}
		if (password === "") {
			setPasswordError("Field is required");
			valid = false;
		}

		if (valid) {
			setIsLoading(true);
			auth
				.signInWithEmailAndPassword(email, password)
				.then((cred) => {
					console.log("cred", cred.user);
					let data = {
						displayName: cred.user.displayName,
						email: cred.user.email,
					};
					console.log(data);
					setIsOpenModal(false);
					setIsLoading(false);
					dispatch(saveAdminUserAction(cred.user));
					alert("Login Successfully");
				})
				.catch((err) => {
					console.log("error " + err);
					setIsLoading(false);
					alert(err.toString());
				});
		}
	};

	const loginModal = () => {
		setIsOpenModal(true);
	};

	const goBack = () => {
		history.goBack();
	};

	const removeUser = () => {
		dispatch(removeAdminUserAction());
	};

	return (
		<>
			<Modals isOpen={isOpenModal} className="login__modal">
				<div className="close__modal">
					<IconButton>
						<CloseRoundedIcon onClick={close} />
					</IconButton>
				</div>
				<Form>
					<InputWrapper
						label="Email"
						error={emailError}
						prefixIcon={<EmailRoundedIcon />}
						onFocus={emailFocus}
						type="text"
						value={email}
						onChangeText={(e) => setEmail(e.target.value)}
					/>
					<InputWrapper
						label="Password"
						error={passwordError}
						prefixIcon={<LockRoundedIcon />}
						onFocus={passwordFocus}
						type="password"
						value={password}
						onChangeText={(e) => setPassword(e.target.value)}
					/>

					<ButtonNLoading
						color="white"
						isLoading={isLoading}
						title="Login"
						onClick={login}
					/>
				</Form>
			</Modals>

			<BookingContainer>
				<Header>
					<div style={{ display: "flex", alignItems: "center" }}>
						<IconButton onClick={goBack}>
							<ArrowBackIosIcon />
						</IconButton>

						<h5>Bookings</h5>
					</div>
					{adminUser ? (
						<Button onClick={removeUser}>Logout</Button>
					) : (
						<Button onClick={loginModal}>Login</Button>
					)}
				</Header>
				{adminUser ? (
					<ItemList>
						{allEventList ? (
							<>
								{allEventList?.map((item, i) => (
									<EventContainer key={i} item={item} />
								))}
							</>
						) : (
							<p style={{ textAlign: "center" }}>Loading</p>
						)}
					</ItemList>
				) : null}
				{/* <ItemList>
					{allEventList?.map((item, i) => (
						<EventContainer key={i} item={item} />
					))}
				</ItemList> */}
			</BookingContainer>
		</>
	);
}

export default Booking;
