import styled from "styled-components";

export const SampleSiteContainer = styled.div`
	width: 100vw;
	height: 100vh;
	overflow-y: scroll;

	/* display: flex;
	flex-direction: column;
	justify-content: center; */
	padding: 1rem 6rem;

	@media only screen and (max-width: 480px) {
		padding: 0.8rem;
	}
	background: #f5f5f5;
`;

export const Header = styled.div`
	height: 200px;
	border: 1px solid #e1e1e1;
	display: flex;
	/* background: red; */
	border-radius: 0.4rem;
	margin-bottom: 1rem;
	background: white;
`;
export const FormContainer = styled.div`
	/* display: flex;
	background: white;
	padding: 1rem;
	margin-bottom: 1rem;
	border: 1px solid #e1e1e1;
	border-radius: 0.4rem;
	
	*/
	background: white;
	display: flex;
	flex-direction: column;
	/* flex-wrap: wrap; */
	margin-bottom: 1rem;
	padding: 1rem;

	@media only screen and (max-width: 480px) {
		/* background: red; */
		flex-wrap: wrap;
	}
`;
export const InputContent = styled.div`
	/* background: red; */
	display: flex;
	/* align-items: center; */
	justify-content: space-between;
	/* flex-wrap: wrap; */
	/* flex-wrap: wrap; */
	margin-bottom: 1rem;

	@media only screen and (max-width: 480px) {
		/* background: red; */
		flex-wrap: wrap;
		flex-direction: column;
	}
`;

export const InputContainer = styled.div`
	min-width: auto;
	max-width: 200px;
	margin-right: 0.8rem;
	@media only screen and (max-width: 480px) {
		width: 100%;
		margin-bottom: 0.8rem;
	}
`;

export const Input = styled.input`
	width: 100%;
	outline: none;
	border: none;
	padding: 0.8rem;
	background: #f5f5f5;
	border-radius: 0.4rem;
`;

export const LogoImage = styled.img`
	width: 100%;
	object-fit: contain;
`;

export const HeaderDetail = styled.div`
	/* height: 80px; */
	border: 1px solid #e1e1e1;
	display: flex;
	padding: 1rem;
	/* background: red; */
	border-radius: 0.4rem;
	margin-bottom: 1rem;
	font-size: 0.8rem;
	font-weight: 600;
	background: white;
	color: grey;
`;

export const Row = styled.div`
	width: 80vw;
	display: flex;
	flex-wrap: wrap;
	padding: 1rem;
	/* background: red; */
`;
export const RowInput = styled.div`
	width: 50%;
	display: flex;
	align-items: center;
	@media only screen and (max-width: 480px) {
		width: 100%;
	}
`;
export const Vertical = styled.div`
	height: 100%;
	width: 1rem;
	@media only screen and (max-width: 480px) {
		width: 0.8rem;
	}
`;

export const ItemList = styled.div`
	display: flex;
	flex-direction: column;
	/* background: red; */
`;

export const ItemContainer = styled.div`
	height: 60px;
	padding: 1rem;
	border: 1px solid #e1e1e1;
	display: flex;
	background: white;
	border-radius: 0.4rem;
`;
export const Image = styled.div`
	width: 200px;
	height: 100%;
	border: none;
	border-right: 1px solid #e1e1e1;
	/* background: red; */
`;
export const TextContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 1rem;
`;
export const Title = styled.div`
	font-size: 0.8rem;
	font-weight: 500;
`;

export const Detail = styled.div`
	font-size: 0.6rem;
	color: grey;
	margin-top: 0.8rem;
`;

export const Button = styled.div`
	margin-top: 0.8rem;
	font-size: 0.8rem;
	font-weight: 500;
	color: blue;
	cursor: pointer;
	transition: 400ms;

	:hover {
		transform: scale(1.1, 1.1);
	}
`;

export const PrimaryButton = styled.div`
	margin-top: 1rem;
	padding: 0.8rem;
	background: #d65a31;
	border-radius: 0.4rem;
	color: white;
`;
