import { useState, useEffect, memo } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { fetchMusic } from "../../Redux/actions";

// Components
import { MusicContainer, MusicList, MusicItem } from "./MusicStyle";
import MusicItemContainer from "./MusicItemContainer/MusicItemContainer";

function Music() {
	// Initilization
	const dispatch = useDispatch();

	// Redux State
	const music = useSelector((state) => state.music);

	useEffect(() => {
		dispatch(fetchMusic());
	}, []);

	return (
		<MusicContainer>
			{music.loading ? (
				<h5 style={{ textAlign: "center" }}>Loading...</h5>
			) : (
				<MusicList>
					{music?.data?.map((item, i) => (
						<MusicItemContainer key={i} item={item} />
					))}
				</MusicList>
			)}
		</MusicContainer>
	);
}

export default memo(Music);
