import styled from "styled-components";

export const EventDetialsContainer = styled.div`
	position: relative;
	height: 100%;
`;

export const InfoContainer = styled.div`
	padding: 0.825rem;
	margin-bottom: 0.825rem;
	border-radius: 0.4rem;
	border: 1px solid #e1e1e1;
	border-top: 2px solid #f7ba01;
`;

export const FormContainer = styled.fieldset`
	position: relative;
	height: calc(100% - 60px);
	border: none;
	overflow-y: auto;
	/* background: red; */
	padding-right: 0.6rem;
`;

export const FooterButton = styled.div`
	position: absolute;
	bottom: -10px;
	left: 0px;
	width: 100%;
	height: 60px;
	/* background: blue; */
`;

export const Row = styled.div`
	display: flex;
	margin-bottom: 1rem;
	/* background: red; */
`;

export const Input = styled.input`
	width: 100%;
	outline: none;
	border: none;
	padding: 0.8rem;
	background: #f5f5f5;
	border-radius: 0.4rem;
`;

export const Vertical = styled.div`
	height: 100%;
	width: 1rem;
`;

export const Title = styled.h5`
	width: 100%;
	text-align: center;
	margin-bottom: 0.8rem;
`;
