import {
	SAVE_USER,
	REMOVE_USER,
	SAVE_EVENT_ID,
	SAVE_EVENT_DETAIL,
	FOOD_STEP,
} from "../../actions/User/useraction";

const initialCartState = {
	user: null,
	eventId: null,
	eventDetail: null,
	foodStep: null,
};

export const userReducer = (state = initialCartState, action) => {
	switch (action.type) {
		case SAVE_USER:
			return {
				...state,
				user: action.payload,
			};

		case REMOVE_USER:
			return {
				...state,
				user: null,
			};
		case SAVE_EVENT_DETAIL:
			return {
				...state,
				eventDetail: action.payload,
			};
		case SAVE_EVENT_ID:
			return {
				...state,
				eventId: action.payload,
			};
		case FOOD_STEP:
			return {
				...state,
				foodStep: action.payload,
			};

		default:
			return state;
	}
};
