import { useState, useEffect } from "react";
// Package
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "@varld/popover";
// Icons

// Action
import {
	addItemToCartAction,
	removeItemToCartAction,
	toggleFoodAction,
} from "../../Redux/actions";

// Comoponents
import {
	CardHorizontalContainer,
	TextContainer,
	Row,
	Column,
	Title,
	Detail,
	PriceTag,
	FilterBorder,
	OutlineLabel,
	IconContainer,
	AddButton,
	AddToCart,
	Decrement,
	Increment,
	Display,
} from "./CardHorizontalStyle";

function CardHorizontal(props) {
	const { item, type, isEnable, tableAvailable, addToCart, onChange } = props;

	const dispatch = useDispatch();

	// Hooks
	const [counter, setCounter] = useState(0);
	const [itemSelected, setItemSelected] = useState(item.isSelected);

	useEffect(() => {
		setItemSelected(item.isSelected);
	}, [item]);

	return (
		<CardHorizontalContainer>
			<Column>
				<Row style={{ justifyContent: "space-between" }}>
					<TextContainer>
						<Row>
							<Title style={{ marginRight: "0.4rem" }}>
								{item?.name}
							</Title>

							<>
								{item?.tags?.map((item, i) => (
									<div key={i}>
										{item === "Spicy" ? (
											<Tooltip content="Spicy">
												<FilterBorder spicy>S</FilterBorder>
											</Tooltip>
										) : item === "Vegan" ? (
											<Tooltip content="Vegan">
												<FilterBorder vegan>Vn</FilterBorder>
											</Tooltip>
										) : item === "Gluten free" ? (
											<Tooltip content="Gluten Free">
												<FilterBorder gluten>G</FilterBorder>
											</Tooltip>
										) : item === "Dairy free" ? (
											<Tooltip content="Dairy free">
												<FilterBorder Dairy>Df</FilterBorder>
											</Tooltip>
										) : item === "Veg" ? (
											<Tooltip content="Veg">
												<FilterBorder Veg>V</FilterBorder>
											</Tooltip>
										) : item === "Non-Veg" ? (
											<Tooltip content="Non-Veg">
												<FilterBorder Nonveg>N</FilterBorder>
											</Tooltip>
										) : null}
									</div>
								))}
							</>
						</Row>

						<div style={{ justifyContent: "space-between" }}>
							<Detail>{item?.description}</Detail>
						</div>
					</TextContainer>
					<input
						type="checkbox"
						checked={itemSelected}
						onChange={(e) => {
							// onChange(e, item);
							let data = {
								type: type,
								id: item.itemId,
							};
							dispatch(toggleFoodAction(data));
							setItemSelected(e.target.checked);
						}}
					></input>
				</Row>
			</Column>
		</CardHorizontalContainer>
	);
}

export default CardHorizontal;
