// Package
import axios from "axios";

export const FETCH_BEVERAGE_REQUEST = "FETCH_BEVERAGE_REQUEST";
export const FETCH_BEVERAGE_SUCCESS = "FETCH_BEVERAGE_SUCCESS";
export const FETCH_BEVERAGE_FAILURE = "FETCH_BEVERAGE_FAILURE";
export const CLEAR_BEVERAGE = "CLEAR_BEVERAGE";

// Actions
export const fetchBeverageRequestAction = () => {
	return {
		type: FETCH_BEVERAGE_REQUEST,
	};
};
export const fetchBeverageSuccessAction = (data) => {
	return {
		type: FETCH_BEVERAGE_SUCCESS,
		payload: data,
	};
};
export const fetchBeverageFailureAction = (error) => {
	return {
		type: FETCH_BEVERAGE_FAILURE,
		payload: error,
	};
};
// Empty Storage
export const clearBeverageAction = () => {
	return {
		type: CLEAR_BEVERAGE,
	};
};

export const fetchBeverage = (providerId) => {
	return (dispatch) => {
		dispatch(fetchBeverageRequestAction());
		console.log("Fetch Beverage Action", providerId);
		axios
			.get(
				`https://us-central1-afoodie-6d649.cloudfunctions.net/provider/${providerId}/display-rank?sortType=asc&itemType=beverage`
			)
			.then((res) => {
				console.log("Response GetBeverageList", res);
				// dispatch(fetchBeverageSuccessAction(res.data.data));
				filterListByTag(dispatch, res.data.data);
			})
			.catch((err) => {
				console.error("Error GetBeverageList", err);
				dispatch(fetchBeverageFailureAction(err));
			});
	};
};

const filterListByTag = (dispatch, drinks) => {
	console.log("Filterlistbytag", drinks);

	let newDrinks = drinks.map((item, i) => {
		return {
			...item,
			items: item?.items.map((menu, i) => {
				return {
					...menu,
					isSelected: false,
				};
			}),
		};
	});
	console.log("new newDrinks", newDrinks);

	dispatch(fetchBeverageSuccessAction(newDrinks));
};
