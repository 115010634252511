import styled from "styled-components";
export const MusicItem = styled.div`
	width: calc(50% - 0.625rem);
	background: #f5f5f5;
	overflow: hidden;
	padding: 0.825rem;
	margin-bottom: 0.625rem;
	margin-right: 0.625rem;
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media only screen and (max-width: 480px) {
		width: 100%;
	}
`;
