import { useState, useEffect } from "react";

import { useDispatch } from "react-redux";

// Actions
import { toggleMusicAction } from "../../../Redux/actions";

// Components
import { MusicItem } from "./MusicItemContainerStyle";

function MusicItemContainer({ item }) {
	// Initilization
	const dispatch = useDispatch();

	const [itemSelected, setItemSelected] = useState(item.isSelected);

	return (
		<MusicItem>
			<p style={{ fontSize: "0.825rem" }}>{item.name}</p>
			{/* <p>{item.id}</p> */}
			<input
				type="checkbox"
				checked={itemSelected}
				onChange={(e) => {
					// let data = {
					// 	id: item.id,
					// 	data: e.target.checked,
					// };
					dispatch(toggleMusicAction(item.id));
					setItemSelected(e.target.checked);
				}}
			/>
		</MusicItem>
	);
}

export default MusicItemContainer;
