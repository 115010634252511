export const ADD_FOOD_ITEM = "ADD_FOOD_ITEM";
export const REMOVE_FOOD_ITEM = "REMOVE_FOOD_ITEM";
export const ADD_DRINK_ITEM = "ADD_DRINK_ITEM";
export const REMOVE_DRINK_ITEM = "REMOVE_DRINK_ITEM";

export const addItemToCartAction = (data) => {
	return {
		type: ADD_FOOD_ITEM,
		payload: data,
	};
};
export const removeItemToCartAction = (data) => {
	return {
		type: REMOVE_FOOD_ITEM,
		payload: data,
	};
};
export const addDrinkAction = (data) => {
	return {
		type: ADD_DRINK_ITEM,
		payload: data,
	};
};
export const removeDrinkAction = (data) => {
	return {
		type: REMOVE_DRINK_ITEM,
		payload: data,
	};
};
