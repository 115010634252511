import styled from "styled-components";

export const ItemContainer = styled.div`
	position: relative;
	/* height: 80px; */
	background: #f5f5f5;
	padding: 0.6rem;
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	/* justify-content: space-between; */
	border-radius: 0.4rem;
	margin-bottom: 0.8rem;
	transition: 200ms;
`;

export const Footer = styled.div`
	position: absolute;
	right: 10px;
	bottom: 5px;
	font-size: 10px;
	font-weight: 700;
`;
export const TopContent = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 60px;
	margin-bottom: 0.8rem;
	/* background: red; */
`;
export const BottomContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100px;
	justify-content: space-between;
	/* background: blue; */
`;

export const Container = styled.div`
	padding: 0.6rem;
	background: white;
	border-radius: 0.4rem;
`;

export const Detail = styled.div`
	font-size: 0.75rem;
	color: #d65a31;
`;
