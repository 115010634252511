import {
	FETCH_MUSIC_REQUEST,
	FETCH_MUSIC_SUCCESS,
	FETCH_MUSIC_FAILURE,
	SELECT_MUSIC,
} from "../../actions/Music/musicaction";

const initialState = {
	loading: false,
	data: null,
	error: "",
};

export const musicReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_MUSIC_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_MUSIC_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				error: "",
			};
		case FETCH_MUSIC_FAILURE:
			return {
				...state,
				loading: false,
				data: null,
				error: action.payload,
			};
		case SELECT_MUSIC:
			console.log("toggle music", state, action.payload);
			let newMusic = state.data.map((item, i) => {
				return {
					...item,
					isSelected:
						item.id === action.payload
							? !item.isSelected
							: item.isSelected,
				};
			});
			console.log("new music", newMusic);
			return {
				...state,
				data: newMusic,
			};

		default:
			return state;
	}
};
