import styled from "styled-components";

export const BookingContainer = styled.div`
	width: 100vw;
	/* background: red; */
`;

export const Header = styled.div`
	background: #f9f9f9;
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #f1f1f1;
`;
export const ItemList = styled.div`
	margin-top: 0.8rem;
	padding: 0.8rem;
`;

export const Form = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: auto;
`;

export const Button = styled.button`
	outline: none;
	border: none;
	padding: 0.75rem;
	border-radius: 0.4rem;
	font-size: 0.8rem;
	font-weight: 500;
	cursor: pointer;
	color: white;
	transition: 400ms;
	background: #d65a31;
`;
