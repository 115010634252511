import {
	ADD_FOOD_ITEM,
	REMOVE_FOOD_ITEM,
	ADD_DRINK_ITEM,
	REMOVE_DRINK_ITEM,
} from "../../actions/Cart/cartaction";

const initialCartState = {
	user: "",
	provider: "",
	food: [],
	drink: [],
};

export const cartReducer = (state = initialCartState, action) => {
	switch (action.type) {
		// case "SAVE_USER_PROVIDER":
		// 	return {
		// 		...state,
		// 		user: action.payload.user,
		// 		provider: action.payload.provider,
		// 	};
		// case "EMPTY_CART":
		// 	return {
		// 		...state,
		// 		cart: [],
		// 	};

		case ADD_FOOD_ITEM:
			return {
				...state,
				food: [...state.food, action.payload],
			};

		case REMOVE_FOOD_ITEM:
			// let selectItemId = action.payload.uuid;
			let selectItemId = action.payload.itemId;
			let data = state.food;

			let index = 0;
			for (let i = 0; i < data.length; i++) {
				if (data[i].itemId === selectItemId) {
					index = i;
					break;
				}
			}
			if (index > -1) {
				data.splice(index, 1);
				console.log("Result", data);
			}
			return {
				...state,
				food: [...data],
			};
		case ADD_DRINK_ITEM:
			return {
				...state,
				drink: [...state.drink, action.payload],
			};

		case REMOVE_DRINK_ITEM:
			// let selectItemId = action.payload.uuid;
			let selectItemDrinkId = action.payload.itemId;
			let dataDrink = state.drink;

			let indexDrink = 0;
			for (let i = 0; i < dataDrink.length; i++) {
				if (dataDrink[i].itemId === selectItemDrinkId) {
					indexDrink = i;
					break;
				}
			}
			if (indexDrink > -1) {
				dataDrink.splice(indexDrink, 1);
				// console.log("Result", data);
			}
			return {
				...state,
				drink: [...dataDrink],
			};

		default:
			return state;
	}
};
