import { useState, useEffect } from "react";

import styled from "styled-components";

// Component
import InputWrapper from "../../InputWrapper/InputWrapper";

function InputArray({
	label,
	guestAdultList,
	guestChildrenList,
	error,
	onFocus,
	setError,
	guestListFromRedux,
}) {
	const [guest, setGuest] = useState("");
	const [guestError, setGuestError] = useState("");
	const [guestList, setGuestList] = useState(guestListFromRedux ?? []);

	useEffect(() => {
		setGuestError(error);
	}, [error]);

	const addGuest = () => {
		if (guest === "") {
			setError();
		} else {
			guestList.push(guest);
			if (label === "Adult") {
				guestAdultList(guestList);
			} else {
				guestChildrenList(guestList);
			}
			setGuest("");
		}
	};

	const removeAllGuest = () => {
		setGuestList([]);
	};

	return (
		<InputArrayContainer>
			<InputWrapper
				label={`Guest ${label}`}
				error={guestError}
				onFocus={onFocus}
				type="text"
				value={guest}
				onChangeText={(e) => setGuest(e.target.value)}
			/>
			<ListContainer>
				{guestList?.map((item, i) => (
					<ArrayItem style={{ fontSize: "0.75rem" }}>
						{i + 1} {" : "}
						{item}
					</ArrayItem>
				))}
			</ListContainer>
			<div style={{ display: "flex", alignItems: "center" }}>
				<Button onClick={addGuest}>Add </Button>
				{guestList.length != 0 ? (
					<Button onClick={removeAllGuest}>Remove</Button>
				) : null}
			</div>
		</InputArrayContainer>
	);
}

const InputArrayContainer = styled.div`
	width: 100%;
	/* background: blue; */
	@media only screen and (max-width: 480px) {
		width: 48%;
	}
`;

const Button = styled.button`
	outline: none;
	border: none;
	width: 90px;
	padding: 0.3rem;
	font-size: 12px;
	border-radius: 0.2rem;
	background: #d65a31;
	color: white;
	margin-right: 0.6rem;
`;

const ListContainer = styled.div`
	width: 100%;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
	margin-bottom: 0.6rem;
	/* background: red; */
`;

const ArrayItem = styled.div``;

export default InputArray;
