import React, { useState, useEffect } from "react";
// Package
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

// Icons
import { IconButton } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

// Actions
import {
	fetchBeverage,
	addDrinkAction,
	removeDrinkAction,
} from "../../Redux/actions";

// Componets
import {
	BeverageListContainer,
	Row,
	MenuHeader,
	TagRow,
	TagFilterContainer,
	SubMenuContainer,
	SubMenuTitle,
	SubMenuItemList,
	BeverageBody,
	LoadingContainer,
} from "./BeverageStyle";
import CardHorizontal from "../CardHorizontal/CardHorizontal";
const Beverage = React.memo(({ providerId }) => {
	// console.log("Beverage", providerId);

	// Initilization
	const dispatch = useDispatch();
	const history = useHistory();

	//
	// const { providerId } = useParams();
	// const [providerId, setProviderId] = useState("ambrosiaIndiaBistro_aptos");

	// Redux
	const menus = useSelector((state) => state.menus.beverage);
	const provider = useSelector((state) => state.menus.provider);

	useEffect(() => {
		console.log(
			"Beverage useeffect",
			providerId,
			provider?.data?.providerDetail?.id
		);
		setTimeout(() => {
			if (!menus.data) {
				dispatch(fetchBeverage(providerId));
			} else {
				if (provider?.data?.providerDetail?.id != providerId) {
					dispatch(fetchBeverage(providerId));
				} else {
					console.log("Beverage Menu already loaded");
				}
			}
		}, 1000);
	}, []);

	const goBack = () => {
		history.goBack();
	};

	const onChange = (e, item) => {
		if (e.target.checked) {
			dispatch(addDrinkAction(item));
			console.log("ssselected", item.name);
		} else {
			dispatch(removeDrinkAction(item));
			console.log("unssselected", item.name);
		}
		// setItemSelected(e.target.checked);
	};

	return (
		<BeverageListContainer style={{ height: "80%" }}>
			<div
				style={{ position: "fixed", top: "1rem", left: "1rem" }}
				onClick={goBack}
			>
				<IconButton>
					<ArrowBackIosIcon />
				</IconButton>
			</div>
			<Row>
				<MenuHeader>Beverages</MenuHeader>
			</Row>
			<BeverageBody>
				{menus?.loading ? (
					<LoadingContainer>Loading...</LoadingContainer>
				) : (
					<>
						{menus?.data?.map((item, i) => (
							<SubMenuContainer key={i}>
								<SubMenuTitle>{item.subMenu}</SubMenuTitle>
								<SubMenuItemList>
									{item.items.map((menu, i) => (
										<CardHorizontal
											key={i}
											item={menu}
											tableAvailable={false}
											addToCart={null}
											onChange={onChange}
										/>
									))}
								</SubMenuItemList>
							</SubMenuContainer>
						))}
					</>
				)}
			</BeverageBody>
		</BeverageListContainer>
	);
});

export default Beverage;
