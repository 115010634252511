import {
	SAVE_ADMIN_USER,
	REMOVE_ADMIN_USER,
} from "../../actions/User/adminuseraction";

const initialCartState = {
	adminUser: null,
};

export const adminUserReducer = (state = initialCartState, action) => {
	switch (action.type) {
		case SAVE_ADMIN_USER:
			return {
				...state,
				adminUser: action.payload,
			};

		case REMOVE_ADMIN_USER:
			return {
				...state,
				adminUser: null,
			};

		default:
			return state;
	}
};
