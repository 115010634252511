import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

// menu
import { menusReducer } from "./Menu/menureducer";

// Cart
import { cartReducer } from "./Cart/cartreducer";

import { userReducer } from "./User/userreducer";
// admin user
import { adminUserReducer } from "./User/adminuserreducer";

// music
import { musicReducer } from "./Music/musicreducer";

const rootReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		menus: menusReducer,
		cart: cartReducer,
		user: userReducer,
		adminUser: adminUserReducer,
		music: musicReducer,
	});

export default rootReducer;
