// Package
import axios from "axios";

export const FETCH_PROVIDER_REQUEST = "FETCH_PROVIDER_REQUEST";
export const FETCH_PROVIDER_SUCCESS = "FETCH_PROVIDER_SUCCESS";
export const FETCH_PROVIDER_FAILURE = "FETCH_PROVIDER_FAILURE";

// Actions
export const fetchProviderRequestAction = () => {
	return {
		type: FETCH_PROVIDER_REQUEST,
	};
};
export const fetchProviderSuccessAction = (data) => {
	return {
		type: FETCH_PROVIDER_SUCCESS,
		payload: data,
	};
};
export const fetchProviderFailureAction = (error) => {
	return {
		type: FETCH_PROVIDER_FAILURE,
		payload: error,
	};
};

export const fetchProvider = (providerId) => {
	console.log("Fetch Provider Action", providerId);

	return (dispatch) => {
		dispatch(fetchProviderRequestAction());
		axios
			.get(
				`https://us-central1-afoodie-6d649.cloudfunctions.net/provider/${providerId}/page-info`
			)
			.then((res) => {
				console.log("Response getProvider Info", res);
				getProviderDetail(dispatch, providerId, res.data.data.pageInfo);
			})
			.catch((err) => {
				console.error("Error getProvider Info", err.response);
				dispatch(
					fetchProviderFailureAction(
						err.response.data.data.toString()
					)
				);
			});
	};
};

const getProviderDetail = (dispatch, providerId, pageInfo) => {
	axios
		.get(
			`https://us-central1-afoodie-6d649.cloudfunctions.net/provider/${providerId}`
		)
		.then((res) => {
			console.log("Response Provider Detail", res);
			if (res.data.success) {
				let modify = res.data.data.provider?.Tags;
				modify?.splice(0, 0, "submenu");
				let data = {
					pageInfo: pageInfo,
					providerDetail: res.data.data.provider,
				};
				console.log("Provider", data);
				dispatch(fetchProviderSuccessAction(data));
			}
		})
		.catch((err) => {
			console.error("Error Provider Detail", err);
			dispatch(
				fetchProviderFailureAction(err?.response?.data?.data.toString())
			);
		});
};
