import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";

import "./inputwrapper.css";

function InputWrapper({
	label,
	error,
	prefixIcon,
	suffixIcon,
	onFocus,
	type,
	value,
	onChangeText,
}) {
	return (
		<div className="inputwrapper">
			<div className="row">
				<div className="label">{label}</div>
				<div className="error">{error}</div>
			</div>
			<div className="input">
				<input
					type={type}
					onFocus={onFocus}
					placeholder={`Enter ${label}`}
					value={value}
					onChange={onChangeText}
					readonly
				/>
				<div className="suffix__icon">{suffixIcon}</div>
			</div>
		</div>
	);
}
export default InputWrapper;
