// Package
import axios from "axios";

export const FETCH_MUSIC_REQUEST = "FETCH_MUSIC_REQUEST";
export const FETCH_MUSIC_SUCCESS = "FETCH_MUSIC_SUCCESS";
export const FETCH_MUSIC_FAILURE = "FETCH_MUSIC_FAILURE";
export const SELECT_MUSIC = "SELECT_MUSIC";

// Actions
const fetchMusicRequestAction = () => {
	return {
		type: FETCH_MUSIC_REQUEST,
	};
};
const fetchMusicSuccessAction = (data) => {
	return {
		type: FETCH_MUSIC_SUCCESS,
		payload: data,
	};
};
const fetchMusicFailureAction = (error) => {
	return {
		type: FETCH_MUSIC_FAILURE,
		payload: error,
	};
};

export const toggleMusicAction = (data) => {
	return {
		type: SELECT_MUSIC,
		payload: data,
	};
};

export const fetchMusic = () => {
	console.log("Fetch Music Action");
	return (dispatch, getState) => {
		if (!getState().music.data) {
			dispatch(fetchMusicRequestAction());
			axios
				.get(`https://us-central1-eatdrinksing.cloudfunctions.net/musics`)
				.then((res) => {
					console.log("Response GetMusicList", res);
					let newMusic = res.data.data.songs.map((item, i) => {
						return {
							...item,
							isSelected: false,
						};
					});

					dispatch(fetchMusicSuccessAction(newMusic));
				})
				.catch((err) => {
					console.error("Error GetMusicList", err.response);
					dispatch(fetchMusicFailureAction(err.response.toString()));
				});
		} else {
			console.log("Music data already loaded");
		}
	};
};
