import { Switch, Route } from "react-router-dom";

// Components
import SampleSite from "./Components/SampleSite/SampleSite";
import Booking from "./Pages/Booking/Booking";
import ConfettiComponent from "./Components/Confetti/ConfettiComponent";

function App() {
	return (
		<Switch>
			<Route exact path="/" component={() => <SampleSite />} />
			<Route path="/booking" component={() => <Booking />} />
			<Route component={() => <p>Root Page not foundss</p>} />
		</Switch>
	);
}

export default App;
