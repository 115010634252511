import { useState, useEffect } from "react";

// Package
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "react-phone-number-input/style.css";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Actions
import { saveFoodStepAction } from "../../../Redux/actions";

// Icons
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";

// Coponent
import { InfoContainer } from "./AccordianMenuStyle";
import Food from "../../Food/Food";
import Beverage from "../../Beverage/Beverage";
import EventDetails from "../../EventDetails/EventDetails";
import ButtonNLoading from "../../ButtonNLoading/ButtonNLoading";
import Music from "../../Music/Music";

// Functions
const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: "50%",
		flexShrink: 0,
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
}));

function AccordianMenu({ item }) {
	// Initilization
	const history = useHistory();
	const dispatch = useDispatch();
	const classes = useStyles();

	// Redux
	const provider = useSelector((state) => state.menus?.provider);
	const foods = useSelector((state) => state.cart?.food);
	const menus = useSelector((state) => state.menus.food?.data?.submenu);
	const drinks = useSelector((state) => state.menus.beverage?.data);
	const beverages = useSelector((state) => state.cart?.drink);
	const user = useSelector((state) => state.user?.user);
	const foodStep = useSelector((state) => state.user?.foodStep);
	const eventId = useSelector((state) => state.user?.eventId);

	// Hooks
	const [expanded, setExpanded] = useState(false);
	const [whichAccordian, setWhichAccordian] = useState("");

	// UI
	const [isLoading, setIsLoading] = useState(false);
	const [isFood, setIsFood] = useState(false);
	const [isDrink, setIsDrink] = useState(false);

	// Functions
	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
		setWhichAccordian(panel);
	};

	const saveFood = () => {
		setIsFood(true);
		console.log("savefood", menus);
		// let newFood = menus.forEach((item, i) => {
		// 	return item.items.filter((menu) => menu.isSelected === true);
		// });
		let allItem = [];
		menus.forEach((item) => {
			let ff = item.items.filter((menu) => menu.isSelected === true);
			console.log(ff.length);
			if (ff.length > 0) {
				allItem.push(...ff);
			}
		});

		console.log("new food", allItem);

		let data = {
			foods: allItem,
		};
		console.log(data, eventId);
		axios
			.put(
				`https://us-central1-eatdrinksing.cloudfunctions.net/events/${eventId}/foods`,
				data
			)
			.then((res) => {
				alert("Food Saved");
				dispatch(saveFoodStepAction("complete"));
				setIsFood(false);
			})
			.catch((err) => {
				console.log("Error fodo asave", err.response);
				setIsFood(false);
			});
	};
	const saveDrink = () => {
		console.log("save beverage", drinks);
		// let allItem = [];
		// drinks.forEach((item) => {
		// 	let ff = item.items.filter((menu) => menu.isSelected === true);
		// 	console.log(ff.length);
		// 	if (ff.length > 0) {
		// 		allItem.push(...ff);
		// 	}
		// });

		// console.log("new food", allItem);

		let data = {
			beverages: beverages,
		};
		// axios
		// 	.put(
		// 		`https://us-central1-eatdrinksing.cloudfunctions.net/events/${eventId}/beverages`,
		// 		data
		// 	)
		// 	.then((res) => {
		// 		alert("Drinks Saved");
		// 		setIsDrink(false);
		// 	})
		// 	.catch((err) => {
		// 		setIsDrink(false);
		// 	});
	};

	return (
		<Accordion
			expanded={expanded === item.value}
			onChange={handleChange(item.value)}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1bh-content"
				id="panel1bh-header"
			>
				<h4 className={classes.heading}>{item.name}</h4>
				<p className={classes.secondaryHeading}>{item.detail}</p>
			</AccordionSummary>
			<AccordionDetails
				style={{
					flexDirection: "column",
					height:
						whichAccordian === "foods" || "drinks" ? "430px" : "400px",
					overflow: "hidden",
				}}
			>
				{whichAccordian === "foods" ? (
					<div
						style={{
							position: "relative",
							// flexDirection: "column",
							// minHeight: "auto",
							height: "400px",
						}}
					>
						{eventId === null ? (
							<InfoContainer
								style={
									{
										// position: "absolute",
										// top: "0px",
										// width: "100%",
									}
								}
							>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "0.625rem",
									}}
								>
									<InfoRoundedIcon style={{ color: "#f7ba01" }} />
									<p
										style={{
											fontWeight: "600",
											marginLeft: "0.825rem",
										}}
									>
										Info
									</p>
								</div>
								<p style={{ color: "grey" }}>
									Create Event To Continue.
								</p>
							</InfoContainer>
						) : null}

						<Food providerId="ambrosiaIndiaBistro_aptos" />
						{eventId && (
							<div
								style={{
									position: "absolute",
									width: "100%",
									bottom: "0px",
									height: "20%",
								}}
							>
								<ButtonNLoading
									color="white"
									isLoading={isLoading}
									title="Save Food"
									onClick={saveFood}
								/>
							</div>
						)}
					</div>
				) : whichAccordian === "drinks" ? (
					<div
						style={{
							flexDirection: "column",
							minHeight: "auto",
							height: "400px",
						}}
					>
						{eventId === null || foodStep === null ? (
							<InfoContainer
								style={
									{
										// position: "absolute",
										// top: "0px",
										// width: "100%",
									}
								}
							>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "0.625rem",
									}}
								>
									<InfoRoundedIcon style={{ color: "#f7ba01" }} />
									<p
										style={{
											fontWeight: "600",
											marginLeft: "0.825rem",
										}}
									>
										Info
									</p>
								</div>
								<p style={{ color: "grey" }}>
									Choose Food To Continue.
								</p>
							</InfoContainer>
						) : null}
						<Beverage providerId="ambrosiaIndiaBistro_aptos" />
						{foodStep && (
							<div
								style={{
									height: "20%",
								}}
							>
								<ButtonNLoading
									color="white"
									isLoading={isLoading}
									title="Save Drinks"
									onClick={saveDrink}
								/>
							</div>
						)}
					</div>
				) : whichAccordian === "eventdetails" ? (
					<EventDetails />
				) : whichAccordian === "music" ? (
					<Music />
				) : null}
			</AccordionDetails>
		</Accordion>
	);
}

const menu = [
	{
		name: "Event Details",
		value: "eventdetails",
		detail: "Create Event",
	},
	{
		name: "Foods",
		value: "foods",
		detail: "Choose Foods",
	},
	{
		name: "Drinks",
		value: "drinks",
		detail: "Choose Drinks",
	},
	{
		name: "Music",
		value: "music",
		detail: "Choose Music",
	},
];

export default AccordianMenu;
