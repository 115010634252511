import { useState, useEffect } from "react";

// Package
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

// Icons
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";

import {
	SampleSiteContainer,
	Header,
	HeaderDetail,
	FormContainer,
	InputContent,
	InputContainer,
	Input,
	PrimaryButton,
	Vertical,
	ItemContainer,
	Image,
	TextContainer,
	Title,
	Detail,
	Button,
	Row,
	ItemList,
	LogoImage,
	RowInput,
	InfoContainer,
} from "./SampleSiteStyle";
import ConfettiComponent from "../Confetti/ConfettiComponent";
import InputWrapper from "../InputWrapper/InputWrapper";

// Actions
import {
	fetchProvider,
	clearFoodAction,
	clearBeverageAction,
	addItemToCartAction,
	saveUserAction,
	removeUserAction,
} from "../../Redux/actions";

import { validateEmail } from "../../Helper/emailValidator";

// Components
import profile from "./dd.png";
import Food from "../Food/Food";
import Beverage from "../Beverage/Beverage";
import EventDetails from "../EventDetails/EventDetails";
import ButtonNLoading from "../ButtonNLoading/ButtonNLoading";
import Music from "../Music/Music";
import AccordianMenu from "./AccordianMenu/AccordianMenu";

// Functions
// const useStyles = makeStyles((theme) => ({
// 	root: {
// 		width: "100%",
// 	},
// 	heading: {
// 		fontSize: theme.typography.pxToRem(15),
// 		flexBasis: "50%",
// 		flexShrink: 0,
// 	},
// 	secondaryHeading: {
// 		fontSize: theme.typography.pxToRem(15),
// 		color: theme.palette.text.secondary,
// 	},
// }));

function SampleSite() {
	// Initilization
	const history = useHistory();
	const dispatch = useDispatch();
	// const classes = useStyles();

	// Redux
	const provider = useSelector((state) => state.menus?.provider);
	const foods = useSelector((state) => state.cart?.food);
	const beverages = useSelector((state) => state.cart?.drink);
	const user = useSelector((state) => state.user?.user);
	const eventId = useSelector((state) => state.user?.eventId);

	// Hooks
	const [expanded, setExpanded] = useState(false);
	const [whichAccordian, setWhichAccordian] = useState("");
	const [form, setForm] = useState({
		email: {
			value: user?.email ?? "",
			error: "",
		},
		firstName: {
			value: user?.firstName ?? "",
			error: "",
		},
		lastName: {
			value: user?.lastName ?? "",
			error: "",
		},
		phoneno: {
			value: user?.phoneno ?? "",
			error: "",
		},
	});
	const [phoneNumber, setPhoneNumber] = useState(user?.phoneno ?? "");
	const focus = (value) => {
		onChangeFormError("", value);
	};

	// UI
	const [isLoading, setIsLoading] = useState(false);
	const [isFood, setIsFood] = useState(false);
	const [isDrink, setIsDrink] = useState(false);

	const onChangeForm = (e, key) => {
		setForm((prevState) => {
			return {
				...prevState,
				[key]: {
					...prevState[key],
					value: e.target.value,
				},
			};
		});
	};
	const onChangeFormError = (error, key) => {
		setForm((prevState) => {
			return {
				...prevState,
				[key]: {
					...prevState[key],
					error: error,
				},
			};
		});
	};

	// Functions
	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
		setWhichAccordian(panel);
	};

	useEffect(() => {
		if (!provider?.data) {
			dispatch(fetchProvider("ambrosiaIndiaBistro_aptos"));
		} else {
			if (
				provider?.data?.providerDetail?.id != "ambrosiaIndiaBistro_aptos"
			) {
				dispatch(fetchProvider("ambrosiaIndiaBistro_aptos"));
				dispatch(clearFoodAction());
				dispatch(clearBeverageAction());
			}
		}
	}, []);

	const saveUser = () => {
		let isValid = true;

		if (form.firstName.value === "") {
			onChangeFormError("Field is required", "firstName");
			isValid = false;
		}
		if (form.lastName.value === "") {
			onChangeFormError("Field is required", "lastName");
			isValid = false;
		}
		if (form.email.value === "") {
			onChangeFormError("Field is required", "email");
			isValid = false;
		}
		if (!validateEmail(form.email.value)) {
			onChangeFormError("Invalid Email", "email");
			isValid = false;
		}
		if (phoneNumber === "" || phoneNumber === undefined) {
			onChangeFormError("Field is required", "phoneno");
			// alert("Field requied");
			isValid = false;
		}
		if (isValid) {
			let data = {
				email: form.email.value,
				firstName: form.firstName.value,
				lastName: form.lastName.value,
				phoneno: phoneNumber,
				date: Date(),
			};
			console.log(data);
			dispatch(saveUserAction(data));
			alert("User detail Saved");
		}
	};

	const booking = () => {
		history.push(`booking`);
	};

	const saveFood = () => {
		setIsFood(true);
		console.log("savefood", foods);

		let data = {
			foods: foods,
		};
		console.log(data, eventId);
		axios
			.put(
				`https://us-central1-eatdrinksing.cloudfunctions.net/events/${eventId}/foods`,
				data
			)
			.then((res) => {
				alert("Food Saved");
				setIsFood(false);
			})
			.catch((err) => {
				console.log("Error fodo asave", err.response);
				setIsFood(false);
			});
	};
	const saveDrink = () => {
		setIsDrink(true);
		console.log("save beverage", beverages);
		let id = user.email;
		let data = {
			beverages: beverages,
		};
		axios
			.put(
				`https://us-central1-eatdrinksing.cloudfunctions.net/events/${eventId}/beverages`,
				data
			)
			.then((res) => {
				alert("Drinks Saved");
				setIsDrink(false);
			})
			.catch((err) => {
				setIsDrink(false);
			});
	};

	return (
		<SampleSiteContainer>
			{/* <ConfettiComponent /> */}
			<Header>
				<LogoImage src={profile} />
			</Header>
			<HeaderDetail>
				Organize your event like no other. With best tasting food, pairings
				of great wines and mixed drinks and Bollywood classic songs. Your
				event is sure to be an event remember for a lifetime.
			</HeaderDetail>

			<FormContainer>
				<InputContent>
					<RowInput>
						<InputWrapper
							label="First Name"
							error={form.firstName.error}
							onFocus={() => focus("firstName")}
							type="text"
							value={form.firstName.value}
							onChangeText={(e) => onChangeForm(e, "firstName")}
						/>
						<Vertical />
						<InputWrapper
							label="Last Name"
							error={form.lastName.error}
							onFocus={() => focus("lastName")}
							type="text"
							value={form.lastName.value}
							onChangeText={(e) => onChangeForm(e, "lastName")}
						/>
					</RowInput>
					<Vertical />
					<RowInput>
						<InputWrapper
							label="Email"
							error={form.email.error}
							onFocus={() => focus("email")}
							type="email"
							value={form.email.value}
							onChangeText={(e) => onChangeForm(e, "email")}
						/>
						<Vertical />
						{/* <InputWrapper
							label="Phone number"
							error={form.phoneno.error}
							onFocus={() => focus("phoneno")}
							type="text"
							value={form.phoneno.value}
							onChangeText={(e) => onChangeForm(e, "phoneno")}
						/> */}
						<div
							style={{
								marginBottom: "10px",
								width: "50%",
							}}
						>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									marginBottom: "8px",
								}}
							>
								<label
									style={{ fontSize: "0.75rem", fontWeight: "600" }}
								>
									Phone number
								</label>
								<p
									style={{
										fontSize: "0.75rem",
										fontWeight: "600",
										color: "#db714e",
										fontWeight: "300",
									}}
								>
									{form.phoneno.error}
								</p>
							</div>
							<PhoneInput
								style={{
									padding: "0.7rem 0.8rem",
									border: "0.5px solid #f3f3f3",
									background: "#f5f5f5",
									borderRadius: "0.4rem",
								}}
								onFocus={() => focus("phoneno")}
								placeholder="Enter phone number"
								value={phoneNumber ?? form.phoneno.value}
								onChange={setPhoneNumber}
							/>
						</div>
					</RowInput>
				</InputContent>

				<div style={{ width: "200px" }}>
					<ButtonNLoading
						color="white"
						isLoading={isLoading}
						title="Save and Add Event Details"
						onClick={saveUser}
					/>
				</div>
			</FormContainer>

			<ItemList>
				{menu.map((item, i) => (
					<AccordianMenu key={i} item={item} />
				))}
			</ItemList>

			{/* <PrimaryButton onClick={booking}>Booking</PrimaryButton> */}
		</SampleSiteContainer>
	);
}

const menu = [
	{
		name: "Event Details",
		value: "eventdetails",
		detail: "Create Event",
	},
	{
		name: "Foods",
		value: "foods",
		detail: "Choose Foods",
	},
	{
		name: "Drinks",
		value: "drinks",
		detail: "Choose Drinks",
	},
	{
		name: "Music",
		value: "music",
		detail: "Choose Music",
	},
];

export default SampleSite;
