import React, { useState, useEffect } from "react";
// Package
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

// Icons
import { IconButton } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

// Actions
import { fetchFood } from "../../Redux/actions";
// Action
import {
	addItemToCartAction,
	removeItemToCartAction,
} from "../../Redux/actions";

// Componets
import {
	FoodListContainer,
	Row,
	MenuHeader,
	TagRow,
	TagFilterContainer,
	SubMenuContainer,
	SubMenuTitle,
	SubMenuItemList,
	FoodBody,
	LoadingContainer,
} from "./FoodStyle";
import CardHorizontal from "../CardHorizontal/CardHorizontal";

// Others
// import { tagFilterList } from "../../../Assets/tagFilterList";

const Food = React.memo(({ providerId }) => {
	// console.log("Food", providerId);

	// Initilization
	const history = useHistory();
	const dispatch = useDispatch();

	//
	// const { providerId } = useParams();
	// const [providerId, setproviderId] = useState(providerId);

	// Redux
	const menus = useSelector((state) => state.menus.food);
	const provider = useSelector((state) => state.menus.provider);
	const eventId = useSelector((state) => state.user?.eventId);

	// Hook
	const [showSubmenuOrTagmenu, setShowSubmenuOrTagmenu] = useState("submenu");
	// const [selectedTag, setSelectedTag] = useState({
	// 	value: "submenu",
	// 	label: "Sub Menu",
	// });
	const [selectedTag, setSelectedTag] = useState("submenu");
	const [menuTagList, setMenuTagList] = useState(null);

	useEffect(() => {
		console.log("Food useeffect");
		setTimeout(() => {
			if (!menus?.data) {
				dispatch(fetchFood(providerId));
			} else {
				if (provider?.data?.providerDetail?.id != providerId) {
					dispatch(fetchFood(providerId));
				} else {
					console.log("Food Menu already loaded");
				}
			}
		}, 1000);
	}, []);

	// Function
	const onFilterSelect = (item) => {
		console.log("onFilterSelect", item);

		setSelectedTag(item);
		if (item === "submenu") {
			// setUpdateList(!updateList);
			setShowSubmenuOrTagmenu("submenu");
		} else {
			// if (selectedTag === "Spicy") {
			// 	console.log(menus?.data?.tags);
			// 	setMenuTagList(menus?.data?.tags["Spicy"]);
			// } else if (selectedTag === "Vegan") {
			// 	setMenuTagList(menus?.data?.tags["Vegan"]);
			// } else if (selectedTag === "Gluten free") {
			// 	setMenuTagList(menus?.data?.tags["Gluten free"]);
			// } else if (selectedTag === "Dairy free") {
			// 	setMenuTagList(menus?.data?.tags["Dairy free"]);
			// } else if (selectedTag === "Veg") {
			// 	setMenuTagList(menus?.data?.tags["Veg"]);
			// } else if (selectedTag === "Non-Veg") {
			// 	setMenuTagList(menus?.data?.tags["Non-Veg"]);
			// }
			let allItems = [];
			menus.data.submenu.map((item, i) => {
				item?.items.map((menu, i) => {
					allItems.push(menu);
				});
			});

			let tagItems = allItems.reduce((accumulator, currentValue) => {
				let tags = currentValue.tags;
				if (tags) {
					tags.forEach((tag) => {
						let subMenu = currentValue.sub_menu;
						if (accumulator.hasOwnProperty(tag)) {
							let list = accumulator[tag];
							let element = list.find(
								(value) => value.subMenu === subMenu
							);
							if (element) {
								element.items.push(currentValue);
							} else {
								let json = {
									subMenu: subMenu,
									items: [currentValue],
								};
								list.push(json);
							}
						} else {
							let json = {
								subMenu: subMenu,
								items: [currentValue],
							};
							accumulator[tag] = [json];
						}
					});
				}
				return accumulator;
			}, {});
			console.log("ashish data new", tagItems);
			console.log("tag by submenu", tagItems?.[item]);
			setMenuTagList(tagItems?.[item]);
			setShowSubmenuOrTagmenu("tagmenu");
		}
	};

	const goBack = () => {
		history.goBack();
	};

	const onChange = (e, item) => {
		if (e.target.checked) {
			dispatch(addItemToCartAction(item));
			console.log("ssselected", item.name);
		} else {
			dispatch(removeItemToCartAction(item));
			console.log("unssselected", item.name);
		}
		// setItemSelected(e.target.checked);
	};

	return (
		<FoodListContainer style={{ height: "80%" }}>
			<div
				style={{ position: "fixed", top: "1rem", left: "1rem" }}
				onClick={goBack}
			>
				<IconButton>
					<ArrowBackIosIcon />
				</IconButton>
			</div>

			<Row>
				<MenuHeader style={{ width: "auto" }}>Foods</MenuHeader>
				<TagRow>
					{provider?.data?.providerDetail?.Tags?.map((item, i) => (
						<div key={i} onClick={() => onFilterSelect(item)}>
							{item === "submenu" ? (
								<TagFilterContainer
									style={{
										background:
											selectedTag === "submenu" ? "#212121" : null,
										color: selectedTag === "submenu" ? "white" : null,
									}}
								>
									All
								</TagFilterContainer>
							) : item === "Spicy" ? (
								<TagFilterContainer
									Spicy
									style={{
										background:
											selectedTag === "Spicy" ? "red" : null,
										color: selectedTag === "Spicy" ? "white" : null,
									}}
								>
									Spice
								</TagFilterContainer>
							) : item === "Vegan" ? (
								<TagFilterContainer
									Vegan
									style={{
										background:
											selectedTag === "Vegan" ? "#22b483" : null,
										color: selectedTag === "Vegan" ? "white" : null,
									}}
								>
									Vegan
								</TagFilterContainer>
							) : item === "Gluten free" ? (
								<TagFilterContainer
									Gluten
									style={{
										background:
											selectedTag === "Gluten free" ? "blue" : null,
										color:
											selectedTag === "Gluten free" ? "white" : null,
									}}
								>
									Gluten Free
								</TagFilterContainer>
							) : item === "Dairy free" ? (
								<TagFilterContainer
									Dairy
									style={{
										background:
											selectedTag === "Dairy free"
												? "#286fde"
												: null,
										color:
											selectedTag === "Dairy free" ? "white" : null,
									}}
								>
									Dairy free
								</TagFilterContainer>
							) : item === "Veg" ? (
								<TagFilterContainer
									Veg
									style={{
										background:
											selectedTag === "Veg" ? "#1d7f29" : null,
										color: selectedTag === "Veg" ? "white" : null,
									}}
								>
									Veg
								</TagFilterContainer>
							) : item === "Non-Veg" ? (
								<TagFilterContainer
									Nonveg
									style={{
										background:
											selectedTag === "Non-Veg" ? "#8d272b" : null,
										color: selectedTag === "Non-Veg" ? "white" : null,
									}}
								>
									Non-Veg
								</TagFilterContainer>
							) : null}
						</div>
					))}
				</TagRow>
			</Row>

			<FoodBody>
				{menus.loading ? (
					<LoadingContainer>Loading...</LoadingContainer>
				) : (
					<>
						<h5
							style={{
								marginLeft: "0.8rem",
								textTransform: "capitalize",
							}}
						>
							{selectedTag}
						</h5>
						{showSubmenuOrTagmenu === "submenu" ? (
							<>
								{menus?.data?.submenu?.map((item, i) => (
									<SubMenuContainer key={i}>
										<SubMenuTitle>{item.subMenu}</SubMenuTitle>
										<SubMenuItemList>
											{item.items.map((menuItem, i) => (
												<CardHorizontal
													key={i}
													type="submenu"
													item={menuItem}
													tableAvailable={false}
													addToCart={null}
													onChange={onChange}
												/>
											))}
										</SubMenuItemList>
									</SubMenuContainer>
								))}
								{/* <p>Sub menus</p> */}
							</>
						) : showSubmenuOrTagmenu === "tagmenu" ? (
							<>
								{menuTagList?.map((item, i) => (
									<div key={i}>
										{item.items.length === 0 ? null : (
											<>
												<SubMenuContainer key={i}>
													<SubMenuTitle>
														{item.subMenu}
													</SubMenuTitle>
													<SubMenuItemList>
														{item.items.map((menuItem, i) => (
															<CardHorizontal
																key={i}
																item={menuItem}
																type="tags"
																tableAvailable={false}
																addToCart={null}
																onChange={onChange}
															/>
														))}
													</SubMenuItemList>
												</SubMenuContainer>
											</>
										)}
									</div>
								))}
							</>
						) : null}
					</>
				)}
			</FoodBody>
		</FoodListContainer>
	);
});

export default Food;
