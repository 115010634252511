import { useState, useEffect } from "react";

// Package
import axios from "axios";
import TimeKeeper from "react-timekeeper";
import { useDispatch, useSelector } from "react-redux";

// Icon
import AccessTimeRoundedIcon from "@material-ui/icons/AccessTimeRounded";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";

// Actions
import { saveEventIdAction, saveEventDetailAction } from "../../Redux/actions";

// Components
import {
	EventDetialsContainer,
	FormContainer,
	Row,
	Input,
	Vertical,
	Title,
	FooterButton,
	InfoContainer,
} from "./EventDetailsStyle";
import ButtonNLoading from "../ButtonNLoading/ButtonNLoading";
import InputArray from "./InputArray/InputArray";
import InputWrapper from "../InputWrapper/InputWrapper";

function EventDetails() {
	// Initilization
	const dispatch = useDispatch();

	// Redux State
	const user = useSelector((state) => state.user.user);
	const eventDetail = useSelector((state) => state.user.eventDetail);

	// Hooks
	const [eventDetailsForm, setEventDetailsForm] = useState({
		date: {
			value: eventDetail?.date ?? "",
			error: "",
		},
		time: {
			value: eventDetail?.time ?? "12:00pm",
			error: "",
		},
		occasion: {
			value: eventDetail?.ocasion ?? "",
			error: "",
		},
		address: {
			value: eventDetail?.venu?.address ?? "",
			error: "",
		},
		address2: {
			value: eventDetail?.venu?.["address-2"] ?? "",
			error: "",
		},
		city: {
			value: eventDetail?.venu?.city ?? "",
			error: "",
		},
		state: {
			value: eventDetail?.venu?.state ?? "",
			error: "",
		},
		zip: {
			value: eventDetail?.venu?.zip ?? "",
			error: "",
		},
		guestAdult: {
			value: eventDetail?.venu?.["guest(adults)"] ?? "",
			error: "",
		},
		guestChildren: {
			value: eventDetail?.venu?.["guest(children)"] ?? "",
			error: "",
		},
	});

	// UI
	const [isLoading, setisLoading] = useState(false);
	const [showTime, setShowTime] = useState(false);

	// Function
	const focus = (value) => {
		onChangeFormError("", value);
	};
	const onChangeForm = (e, key) => {
		setEventDetailsForm((prevState) => {
			return {
				...prevState,
				[key]: {
					...prevState[key],
					value: e.target.value,
				},
			};
		});
	};

	const onChangeFormError = (error, key) => {
		setEventDetailsForm((prevState) => {
			return {
				...prevState,
				[key]: {
					...prevState[key],
					error: error,
				},
			};
		});
	};

	// const guestAdultList = (list) => {
	// 	setEventDetailsForm((prevState) => {
	// 		return {
	// 			...prevState,
	// 			guestAdultList: {
	// 				...prevState.guestAdultList,
	// 				value: list,
	// 			},
	// 		};
	// 	});
	// };

	// const guestChildrenList = (list) => {
	// 	setEventDetailsForm((prevState) => {
	// 		return {
	// 			...prevState,
	// 			guestChildrenList: {
	// 				...prevState.guestChildrenList,
	// 				value: list,
	// 			},
	// 		};
	// 	});
	// };

	const saveEvent = () => {
		let allValid = true;

		if (eventDetailsForm.date.value === "") {
			onChangeFormError("Field is required", "date");
			allValid = false;
		}
		if (eventDetailsForm.time.value === "") {
			onChangeFormError("Field is required", "time");
			allValid = false;
		}
		if (eventDetailsForm.occasion.value === "") {
			onChangeFormError("Field is required", "occasion");
			allValid = false;
		}
		if (eventDetailsForm.address.value === "") {
			onChangeFormError("Field is required", "address");
			allValid = false;
		}
		if (eventDetailsForm.address2.value === "") {
			onChangeFormError("Field is required", "address2");
			allValid = false;
		}
		if (eventDetailsForm.city.value === "") {
			onChangeFormError("Field is required", "city");
			allValid = false;
		}
		if (eventDetailsForm.state.value === "") {
			onChangeFormError("Field is required", "state");
			allValid = false;
		}
		if (eventDetailsForm.zip.value === "") {
			onChangeFormError("Field is required", "zip");
			allValid = false;
		}
		if (eventDetailsForm.guestAdult.value === "") {
			onChangeFormError("Field is required", "guestAdult");
			allValid = false;
		}
		if (eventDetailsForm.guestAdult.value < 0) {
			onChangeFormError("Field can't be negative", "guestAdult");
			allValid = false;
		}
		if (eventDetailsForm.guestChildren.value === "") {
			onChangeFormError("Field is required", "guestChildren");
			allValid = false;
		}
		if (eventDetailsForm.guestChildren.value < 0) {
			onChangeFormError("Field can't be negative", "guestChildren");
			allValid = false;
		}
		if (allValid) {
			setisLoading(true);
			let data = {
				date: eventDetailsForm.date.value,
				time: eventDetailsForm.time.value,
				ocasion: eventDetailsForm.occasion.value,
				venu: {
					address: eventDetailsForm.address.value,
					"address-2": eventDetailsForm.address2.value,
					city: eventDetailsForm.city.value,
					state: eventDetailsForm.state.value,
					zip: eventDetailsForm.zip.value,
					"guest(adults)": eventDetailsForm.guestAdult.value,
					"guest(children)": eventDetailsForm.guestChildren.value,
				},
			};
			console.log("data", data);
			axios
				.post(
					`https://us-central1-eatdrinksing.cloudfunctions.net/events/?email=${user.email}`,
					data
				)
				.then((res) => {
					console.log("Response Create an event", res);
					if (res.data.success) {
						// Event detail save
						dispatch(saveEventDetailAction(data));
						dispatch(saveEventIdAction(res.data.data.event.id));
						sendEmail(res.data.message);
					}
				})
				.catch((err) => {
					console.error("Error Create an event", err.response.data.error);
					alert(err.response.data.error);
					setisLoading(false);
				});
		}
	};

	const sendEmail = (message) => {
		axios
			.post(
				"https://us-central1-eatdrinksing.cloudfunctions.net/emails/send"
			)
			.then((res) => {
				console.log("Response send email", res);
				if (res.data.success) {
					alert(message);
					setisLoading(false);
				}
			})
			.catch((err) => {
				console.error("Error send email", err.response);
				setisLoading(false);
			});
	};

	return (
		<EventDetialsContainer>
			<div
				style={{
					position: "fixed",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					zIndex: "10",
				}}
			>
				{showTime && (
					<TimeKeeper
						time={eventDetailsForm.time.value}
						onChange={(newTime) => {
							console.log(newTime);
							setEventDetailsForm((prevState) => {
								return {
									...prevState,
									time: {
										...prevState.time,
										value: newTime.formatted12,
									},
								};
							});
						}}
						onDoneClick={() => setShowTime(false)}
						switchToMinuteOnHourSelect
					/>
				)}
			</div>

			<FormContainer disabled={!user}>
				{!user ? (
					<InfoContainer>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								marginBottom: "0.625rem",
							}}
						>
							<InfoRoundedIcon style={{ color: "#f7ba01" }} />
							<p style={{ fontWeight: "600", marginLeft: "0.825rem" }}>
								Info
							</p>
						</div>
						<p style={{ color: "grey" }}>
							Please Provider Contact Details to Continue.
						</p>
					</InfoContainer>
				) : null}

				<Row>
					<div style={{ flexBasis: "50%" }}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginBottom: "8px",
							}}
						>
							<label style={{ fontSize: "0.75rem", fontWeight: "600" }}>
								Date
							</label>
							<p
								style={{
									fontSize: "0.75rem",
									fontWeight: "600",
									color: "#db714e",
									fontWeight: "300",
								}}
							>
								{eventDetailsForm.date.error}
							</p>
						</div>
						<Input
							type="date"
							onFocus={() => focus("date")}
							value={eventDetailsForm.date.value}
							onChange={(e) => onChangeForm(e, "date")}
						/>
					</div>
					<Vertical />
					<div style={{ flexBasis: "50%" }}>
						<div style={{}}>
							{/* {showTime && (
								// <TimeKeeper
								// 	time={eventDetailsForm.time.value}
								// 	onChange={(newTime) => {
								// 		console.log(newTime);
								// 		setEventDetailsForm((prevState) => {
								// 			return {
								// 				...prevState,
								// 				time: {
								// 					...prevState.time,
								// 					value: newTime.formatted12,
								// 				},
								// 			};
								// 		});
								// 	}}
								// 	onDoneClick={() => setShowTime(false)}
								// 	switchToMinuteOnHourSelect
								// />
								<TimeKeeper
									time={time}
									onChange={(newTime) => setTime(newTime.formatted12)}
									onDoneClick={() => setShowTime(false)}
									switchToMinuteOnHourSelect
								/>
							)} */}
						</div>

						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginBottom: "8px",
							}}
						>
							<label style={{ fontSize: "0.75rem", fontWeight: "600" }}>
								Time
							</label>
							<p
								style={{
									fontSize: "0.75rem",
									fontWeight: "600",
									color: "#db714e",
									fontWeight: "300",
								}}
							>
								{eventDetailsForm.time.error}
							</p>
						</div>
						<div
							style={{
								borderRadius: "0.4rem",
								padding: "0.6rem",
								background: "#f5f5f5",
								display: "flex",
								alignItem: "center",
								justifyContent: "space-between",
							}}
						>
							<span> {eventDetailsForm.time.value}</span>
							{!showTime && (
								<div onClick={() => (user ? setShowTime(true) : null)}>
									<AccessTimeRoundedIcon />
								</div>
							)}
						</div>

						{/* 
						<Input
							type="time"
							onFocus={() => focus("time")}
							value={eventDetailsForm.time.value}
							onChange={(e) => onChangeForm(e, "time")}
						/> */}
					</div>
				</Row>

				<Row>
					<InputWrapper
						label="Occasion"
						error={eventDetailsForm.occasion.error}
						onFocus={() => focus("occasion")}
						type="text"
						value={eventDetailsForm.occasion.value}
						onChangeText={(e) => onChangeForm(e, "occasion")}
					/>
				</Row>

				<Title>Venue</Title>
				<Row>
					<InputWrapper
						label="Address"
						error={eventDetailsForm.address.error}
						onFocus={() => focus("address")}
						type="text"
						value={eventDetailsForm.address.value}
						onChangeText={(e) => onChangeForm(e, "address")}
					/>
					<Vertical />
					<InputWrapper
						label="Address 2"
						error={eventDetailsForm.address2.error}
						onFocus={() => focus("address2")}
						type="text"
						value={eventDetailsForm.address2.value}
						onChangeText={(e) => onChangeForm(e, "address2")}
					/>
				</Row>

				<Row>
					<InputWrapper
						label="City"
						error={eventDetailsForm.city.error}
						onFocus={() => focus("city")}
						type="text"
						value={eventDetailsForm.city.value}
						onChangeText={(e) => onChangeForm(e, "city")}
					/>
					<Vertical />
					<InputWrapper
						label="State"
						error={eventDetailsForm.state.error}
						onFocus={() => focus("state")}
						type="text"
						value={eventDetailsForm.state.value}
						onChangeText={(e) => onChangeForm(e, "state")}
					/>
				</Row>
				<Row>
					<InputWrapper
						label="Zip"
						error={eventDetailsForm.zip.error}
						onFocus={() => focus("zip")}
						type="number"
						value={eventDetailsForm.zip.value}
						onChangeText={(e) => onChangeForm(e, "zip")}
					/>
				</Row>
				<Title>Guests</Title>
				<Row>
					<InputWrapper
						label="Guest(Adult)"
						error={eventDetailsForm.guestAdult.error}
						onFocus={() => focus("guestAdult")}
						type="number"
						value={eventDetailsForm.guestAdult.value}
						onChangeText={(e) => onChangeForm(e, "guestAdult")}
					/>
					<div style={{ width: "0.75rem" }}></div>
					<InputWrapper
						label="Guest(Children)"
						error={eventDetailsForm.guestChildren.error}
						onFocus={() => focus("guestChildren")}
						type="number"
						value={eventDetailsForm.guestChildren.value}
						onChangeText={(e) => onChangeForm(e, "guestChildren")}
					/>
					{/* <InputArray
						label="Adult"
						guestAdultList={guestAdultList}
						error={eventDetailsForm.guestAdultList.error}
						setError={() => {
							onChangeFormError("Field is required", "guestAdultList");
						}}
						onFocus={() => focus("guestAdultList")}
						guestListFromRedux={eventDetail?.venu?.["guest(adults)"]}
					/>
					<div style={{ width: "0.75rem" }}></div>
					<InputArray
						label="Children"
						guestChildrenList={guestChildrenList}
						error={eventDetailsForm.guestChildrenList.error}
						setError={() => {
							onChangeFormError(
								"Field is required",
								"guestChildrenList"
							);
						}}
						onFocus={() => focus("guestChildrenList")}
						guestListFromRedux={eventDetail?.venu?.["guest(children)"]}
					/> */}
				</Row>
			</FormContainer>
			<FooterButton>
				{user ? (
					<ButtonNLoading
						color="white"
						isLoading={isLoading}
						title="Save"
						onClick={saveEvent}
					/>
				) : null}
			</FooterButton>
		</EventDetialsContainer>
	);
}

export default EventDetails;
